import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";

export type CustomTextFieldProps = {
  name: string;
  label: string;
} & TextFieldProps;

export const TextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <MuiTextField
          label={label}
          value={value}
          onChange={onChange}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  );
};
