import React from "react";
import { Box, Stack } from "@mui/material";
import { TabPanel } from "../TabPanel";
import PlanterTotalsCard from "../../cards/PlanterTotalsCard";
import ApprovedDailyPlanterTalliesPerPlanterCard from "../../cards/ApprovedDailyPlanterTalliesPerPlanterCard";

interface Props {
  value: any;
  theme: any;
  index: number;
}

export const PlantingHistoryTab: React.FC<Props> = ({
  value,
  theme,
  index,
}) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Stack spacing={3} sx={{ width: "100%" }}>
        <PlanterTotalsCard />
        <ApprovedDailyPlanterTalliesPerPlanterCard />
      </Stack>
    </TabPanel>
  );
};
