import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

interface Props extends DatePickerProps<Dayjs> {
  name: string;
  label: string;
}

export const DatePicker: React.FC<Props> = ({ name, label, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <MuiDatePicker
          label={label}
          value={value}
          onChange={onChange}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  );
};
