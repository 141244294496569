import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DropdownSelect from "./inputs/DropdownSelect";
import { Box, ListItem, Slide, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { TextField } from "./inputs/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "./inputs/DatePicker";
import NestedFieldArray from "./inputs/NestedFieldArray";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "../../App";
import { TransitionProps } from "@mui/material/transitions";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_BUTTON_SUBMIT_DAILY_PLANTER_TALLY = "SUBMIT DAILY PLANTER TALLY";
const TXT_BUTTON_ADD_A_BLOCK = "Add A Block";
const TXT_BUTTON_SUBMIT = "Submit";
const TXT_BUTTON_CANCEL = "Cancel";
const TXT_FORM_TITLE_SUBMIT_DAILY_PLANTER_TALLY = "SUBMIT DAILY PLANTER TALLY";
const TXT_FORM_SECTION_OVERVIEW = "Overview";
const TXT_FORM_SECTION_BLOCKS_PLANTED_TODAY = "Blocks Planted Today";
const TXT_INPUT_LABEL_BLOCK_NAME = "Block Name";
const TXT_INPUT_LABEL_TREE_PRICE = "Tree Price";
const TXT_INPUT_LABEL_PLANT_DATE = "Plant Date";
const TXT_INPUT_LABEL_CREWBOSS = "Crewboss";
const TXT_INPUT_LABEL_NOTES = "Notes";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Seedlot {
  name: string;
  bundleSize: number;
  bundleCount: number;
}

interface Block {
  name: string;
  tree_price: number;
  seedlots: Seedlot[];
}

// Defines what the fields will be in the form
interface TallyForm {
  name: string;
  planter: string;
  crewboss: string;
  plantDate: Dayjs | null;
  notes?: string;
  blocks: Block[];
}

export default function DailyPlanterTallyFormDialog() {
  // STATE VARIABLES AND FUNCTIONS
  const [formOpen, setFormOpen] = useState(false);

  // DEFAULT PLANT DATE
  const [defaultDate] = React.useState<Dayjs | null>(dayjs(Date.now()));

  // DEFAULT CREWBOSS
  const defaultPlanterCrewbossIndex = "1";

  // DEFINE FORM DEFAULTS
  const form = useForm<TallyForm>({
    defaultValues: {
      // planter: currentUserId.toString(),
      crewboss: defaultPlanterCrewbossIndex,
      plantDate: defaultDate,
      notes: "",
      blocks: [{ name: "", seedlots: [] }],
    },
  });

  // HANDLE ACTIONS
  const { handleSubmit, control } = form;
  const handleCancelForm = () => {
    setFormOpen(false);
  };
  const handleClickOpenForm = () => {
    setFormOpen(true);
  };

  // VARS FOR FIELD ARRAY
  const { fields, remove, append } = useFieldArray({
    control,
    name: "blocks",
  });

  return (
    <Box>
      {/* BUTTON */}
      <Button
        sx={{
          m: 1,
          color: "white",
          background: theme.palette.primary.main,
          border: "solid",
          borderColor: "white",
          padding: 2,
          width: 300,
          borderRadius: 2,
        }}
        variant="contained"
        onClick={handleClickOpenForm}
      >
        {TXT_BUTTON_SUBMIT_DAILY_PLANTER_TALLY}
      </Button>

      {/* OPEN THE DIALOG */}
      <Dialog
        open={formOpen}
        fullScreen={true}
        scroll="paper"
        TransitionComponent={Transition}
      >
        {/* TITLE */}
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "white",
            background: theme.palette.secondary.main,
            padding: 4,
          }}
        >
          <Typography variant="h6">
            {TXT_FORM_TITLE_SUBMIT_DAILY_PLANTER_TALLY}
          </Typography>
        </DialogTitle>

        {/* CONTENTS OF DIALOG (THE FORM) */}
        <DialogContent
          sx={{
            padding: 0,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "75%", lg: "50%", xl: "40%" },
            }}
          >
            <FormProvider {...form}>
              <form
                onSubmit={
                  // DEFINE HANDLE SUBMIT
                  handleSubmit((inputValues) => {
                    console.log(
                      "INPUT VALUES:",
                      JSON.parse(JSON.stringify(inputValues))
                    );
                    // axios
                    //   .post(
                    //     "/dailyPlanterTallies/",
                    //     JSON.parse(JSON.stringify(inputValues))
                    //   )
                    //   .then((res) => {
                    //     console.log(res);
                    //     console.log(res.data);
                    //   });
                    setFormOpen(false);
                  })
                }
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    padding: 1,
                  }}
                >
                  {/* MAIN OVERVIEW SECTION OF THE FORM */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      paddingBottom: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.main,
                      }}
                    >
                      {TXT_FORM_SECTION_OVERVIEW}
                    </Typography>
                  </Box>
                  {/* SELECT PLANTER */}
                  <DropdownSelect
                    name="planter"
                    label="Planter"
                    dropdownOptions={
                      [{ label: "TEST PLANTER", value: "0" }]
                      //   plantersResult.map((planter: Planter) => ({
                      //   label: planter.name,
                      //   value: planter.id,
                      // }))
                    }
                  />

                  {/* PICK DATE */}
                  <DatePicker
                    name="plantDate"
                    label={TXT_INPUT_LABEL_PLANT_DATE}
                    sx={{
                      m: 1,
                      width: "100%",
                    }}
                  />

                  {/* SELECT CREWBOSS */}
                  <DropdownSelect
                    name="crewboss"
                    label={TXT_INPUT_LABEL_CREWBOSS}
                    dropdownOptions={
                      [{ label: "TEST CREWBOSS", value: "0" }]
                      // crewbossesResult.map(
                      //   (crewboss: Planter) => ({
                      //     label: crewboss.name,
                      //     value: crewboss.id,
                      //   })
                      // )
                    }
                  />

                  {/* NOTES */}
                  <TextField
                    name="notes"
                    label={TXT_INPUT_LABEL_NOTES}
                    multiline
                    sx={{ m: 1, width: "100%" }}
                  ></TextField>
                </Stack>
                {/* BLOCKS SECTION */}
                <Stack
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      paddingBottom: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: theme.palette.secondary.main,
                        borderTop: 2,
                        marginTop: 2,
                        paddingTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        borderColor: theme.palette.secondary.main,
                      }}
                    >
                      {TXT_FORM_SECTION_BLOCKS_PLANTED_TODAY}
                    </Typography>
                  </Box>
                  {/* INFORMATION FOR THE BLOCKS */}
                  {fields.map((block, blockIndex) => {
                    return (
                      // PER BLOCK
                      <ListItem
                        sx={{
                          padding: 0,
                          width: "100%",
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            background: "#E4E4E4",
                            width: "97%",
                            paddingTop: 3,
                            paddingBottom: 2,
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderRadius: 1,
                            border: 2,
                            borderColor: theme.palette.secondary.main,
                          }}
                        >
                          {/* BLOCK TITLE SECTION */}
                          <Stack
                            direction="row"
                            spacing={1}
                            paddingBottom={1}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            {/* INPUT THE NAME OF THE BLOCK */}
                            <TextField
                              name={`blocks.${blockIndex}.name`}
                              label={TXT_INPUT_LABEL_BLOCK_NAME}
                              sx={{
                                width: "100%",
                              }}
                            ></TextField>
                            <TextField
                              name={`blocks.${blockIndex}.tree_price`}
                              label={TXT_INPUT_LABEL_TREE_PRICE}
                              sx={{
                                // m: 1,
                                width: 160,
                              }}
                            ></TextField>

                            {/* DELETE THE BLOCK */}
                            <Button
                              sx={{
                                padding: 0,
                              }}
                              onClick={() => remove(blockIndex)}
                            >
                              <ClearIcon />
                            </Button>
                          </Stack>

                          {/* FILL OUT THE SEEDLOT INFORMATION FOR EACH BLOCK */}
                          <NestedFieldArray
                            name={`blocks.${blockIndex}.seedlots`}
                            label="Seedlots"
                            blockIndex={blockIndex}
                          />
                        </Stack>
                      </ListItem>
                    );
                  })}

                  <Stack
                    spacing={2}
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 1,
                    }}
                  >
                    {/* ADD A NEW BLOCK */}
                    <Box
                      sx={{
                        justifyContent: "center",
                        alignContent: "center",
                        display: "flex",
                        paddingBottom: 4,
                        paddingTop: 1,
                        borderBottom: 2,
                        borderColor: theme.palette.secondary.main,
                      }}
                    >
                      <Button
                        sx={{
                          color: theme.palette.secondary.main,
                          background: "white",
                          border: "solid",
                          borderColor: theme.palette.secondary.main,
                          width: "50%",
                          borderRadius: 2,
                        }}
                        variant="contained"
                        onClick={() => {
                          append({ name: "", tree_price: 0, seedlots: [] });
                        }}
                      >
                        {TXT_BUTTON_ADD_A_BLOCK}
                      </Button>
                    </Box>
                    <Box sx={{ paddingTop: 2 }}>
                      <Button
                        type="submit"
                        sx={{
                          color: "white",
                          background: theme.palette.secondary.main,
                          border: "solid",
                          borderColor: theme.palette.primary.dark,
                          width: "100%",
                          padding: 2,
                        }}
                        variant="contained"
                      >
                        <Typography variant="h6">
                          {TXT_BUTTON_SUBMIT}
                        </Typography>
                      </Button>
                    </Box>
                    {/* CANCEL BUTTON */}
                    <Button
                      onClick={handleCancelForm}
                      sx={{
                        color: theme.palette.secondary.main,
                        background: "white",
                        width: "100%",
                        paddingBottom: 3,
                      }}
                      variant="text"
                    >
                      {TXT_BUTTON_CANCEL}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
