import React from "react";
import Typography from "@mui/material/Typography";
import ForestIcon from "@mui/icons-material/Forest";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, Stack } from "@mui/material";
import { PageCard } from "./PageCard";
import { CardTitle } from "./CardTitle";
import { planterTotalsResult } from "../pages/MyPlantingPage";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_TITLE_PLANT_TOTALS = "SEASON OVERVIEW";

export default function PlanterTotalsCard() {
  return (
    <PageCard>
      <CardTitle title={TXT_TITLE_PLANT_TOTALS} />
      <Stack
        direction="row"
        spacing={10}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        {/* TREES TOTAL SECTION */}
        <Stack
          direction="row"
          sx={{
            m: 1,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          {/* FOREST ICON */}
          <Box
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <ForestIcon
              sx={{
                height: "100%",
                color: "white",
              }}
            />
          </Box>
          {/* TREES TOTAL */}
          <Box
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <Typography variant="h6">{planterTotalsResult.trees}</Typography>
          </Box>
        </Stack>

        {/* EARNINGS SECTION */}
        <Stack
          direction="row"
          sx={{
            m: 1,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          {/* EARNINGS ICON */}
          <Box
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <AttachMoneyIcon
              sx={{
                height: "100%",
                color: "white",
              }}
            />
          </Box>
          {/* EARNINGS TOTAL */}
          <Box
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <Typography variant="h6">{planterTotalsResult.earnings}</Typography>
          </Box>
        </Stack>
      </Stack>
    </PageCard>
  );
}
