import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props extends SelectProps<string> {
  name: string;
  label: string;
  dropdownOptions: { label: string; value: string }[];
}

export const DropdownSelect: React.FC<Props> = ({
  name,
  label,
  dropdownOptions,
  ...rest
}) => {
  const { control } = useFormContext();
  const labelId = `${name}-label`;
  const generateMenuItems = () => {
    return dropdownOptions.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    // TODO: Make styling not hard-coded
    <FormControl sx={{ m: 1, width: "100%" }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              labelId={labelId}
              label={label}
              value={value}
              onChange={onChange}
              inputRef={ref}
              {...rest}
            >
              {generateMenuItems()}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};
export default DropdownSelect;
