import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const PrivateRoute = () => {
  const user = useContext(AuthContext);
  console.log("PRIVATE ROUTE USER:", user);
  return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
