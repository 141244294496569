import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import LogInCard from "../cards/LogInCard";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const LogInPage: React.FC = () => {
  // NAVIGATE
  const navigate = useNavigate();

  // CURRENT USER
  const user = useContext(AuthContext);

  // IF ALREADY LOGGED IN GO TO THE DEFAULT HOME PAGE
  useEffect(() => {
    if (user) {
      console.log("LOGGED IN ALREADY!");
      navigate("/MyPlanting");
    }
  });

  return (
    <Box>
      <LogInCard />
    </Box>
  );
};

export default LogInPage;
