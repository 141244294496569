import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import MyPlantingTabs from "../tabs/tabGroups/MyPlantingTabs";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export let planterTallySubmittedResult: boolean;
export let pendingPlanterTalliesResult: any;
export let approvedPlanterTalliesResult: any;
export let pendingExists: boolean;
export let approvedExists: boolean;
export let planterTotalsResult: { earnings: number; trees: number };
export let crewbossesResult: any;
export let plantersResult: any;

// TODO: NOT HARD CODED
const currentUserId = 3;

export const MyPlantingPage: React.FC = () => {
  // NAVIGATE
  const navigate = useNavigate();

  // CURRENT USER
  const user = useContext(AuthContext);

  // IF NOT LOGGED IN, GO TO THE LOG IN PAGE
  useEffect(() => {
    if (!user) {
      console.log("NOT LOGGED IN!");
      navigate("/login");
    }
  });

  const [submitted, setSubmitted] = useState([]);
  const [pendingPlanterTallies, setPendingPlanterTallies] = useState([]);
  const [approvedPlanterTallies, setApprovedPlanterTallies] = useState([]);
  const [planterTotals, setPlanterTotals] = useState([]);
  const [crewbosses, setCrewbosses] = useState([]);
  const [planters, setPlanters] = useState([]);

  const refreshSubmitted = () => {
    axios
      .get(`/dailyPlanterTallyToday/${currentUserId}`)
      .then((res) => res.data)
      .then((submitted) => setSubmitted(submitted));
  };

  const refreshPendingPlanterTallies = () => {
    axios
      .get(`/dailyPlanterTallies/pending/planter/${currentUserId}`)
      .then((res) => res.data)
      .then((data) => setPendingPlanterTallies(data));
  };

  const refreshApprovedPlanterTallies = () => {
    axios
      .get(`/dailyPlanterTallies/approved/planter/${currentUserId}`)
      .then((res) => res.data)
      .then((data) => setApprovedPlanterTallies(data));
  };

  const refreshPlanterTotals = () => {
    axios
      .get(`/planters/totals/${currentUserId}`)
      .then((res) => res.data)
      .then((totals) => setPlanterTotals(totals));
  };

  // PLANTERS
  const refreshPlanters = () => {
    axios
      .get("/planters")
      .then((res) => res.data)
      .then((data) => setPlanters(data));
  };

  // CREWBOSSES
  const refreshCrewbosses = () => {
    axios
      .get("/crewbosses")
      .then((res) => res.data)
      .then((data) => setCrewbosses(data));
  };

  useEffect(() => {
    // refreshSubmitted();
    // refreshPendingPlanterTallies();
    // refreshApprovedPlanterTallies();
    // refreshPlanterTotals();
    // refreshCrewbosses();
    // refreshPlanters();
  }, []);

  planterTallySubmittedResult = (submitted?.at(0) ?? { exists: false }).exists;
  pendingPlanterTalliesResult = pendingPlanterTallies;
  approvedPlanterTalliesResult = approvedPlanterTallies;
  pendingExists = pendingPlanterTallies?.length > 0 ?? false;
  approvedExists = approvedPlanterTallies?.length > 0 ?? false;
  planterTotalsResult = planterTotals?.at(0) ?? { earnings: 0, trees: 0 };
  crewbossesResult = crewbosses;
  plantersResult = planters;

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        // height: "100vh",
      }}
    >
      <MyPlantingTabs />
    </Box>
  );
};

export default MyPlantingPage;
