import React from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Menu from "./features/MainMenu";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import MyCompanyPage from "./features/pages/MyCompanyPage";
import MyPlantingPage from "./features/pages/MyPlantingPage";
import MyCrewPage from "./features/pages/MyCrewPage";
import { LogInPage } from "./features/pages/LogInPage";
import MyCampPage from "./features/pages/MyCampPage";
import { Analytics } from "@vercel/analytics/react";
import HomePage from "./features/pages/HomePage";
import { CssBaseline } from "@mui/material";
import PrivateRoute from "./features/routing/PrivateRoute";

// THEME
export const theme = createTheme({
  palette: {
    primary: {
      main: "#004225",
    },
    secondary: {
      main: "#008f50",
    },
    background: {
      default: "#1f1f1f",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Analytics />
        <BrowserRouter>
          <Menu />
          <Routes>
            {/* <Route path="/" element={<LogInPage />} /> */}
            <Route path="/login" element={<LogInPage />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/myplanting" element={<MyPlantingPage />} />
              {/* <Route path="/mycrew" element={<MyCrewPage />} /> */}
              {/* <Route path="/mycamp" element={<MyCampPage />} /> */}
              {/* <Route path="/employees" element={<MyCompanyPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
