import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import ApprovedPlanterTalliesPerPlanterTable from "../tables/ApprovedPlanterTalliesPerPlanterTable";
import { PageCard } from "./PageCard";
import { CardTitle } from "./CardTitle";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_TITLE_TALLIES = "DAILY PLANTING HISTORY";
const TXT_INFO_NO_TALLIES =
  "You don't have any approved daily planter tally records yet. \
  Submit a daily planter tally to get started. \
  Once your crewboss approves your daily planter tally, it will appear here.";

export default function ApprovedDailyPlanterTalliesPerPlanterCard() {
  // TODO: NOT HARDCODED
  const approvedExists = false;

  return (
    <PageCard>
      <Stack>
        <CardTitle title={TXT_TITLE_TALLIES} />
        {approvedExists ? (
          <ApprovedPlanterTalliesPerPlanterTable />
        ) : (
          <Stack
            direction="row"
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <WatchLaterOutlinedIcon
              sx={{
                marginRight: 1,
                height: "100%",
                color: "white",
              }}
            />
            <Typography variant="body1">{TXT_INFO_NO_TALLIES}</Typography>
          </Stack>
        )}
      </Stack>
    </PageCard>
  );
}
