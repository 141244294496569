import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

export type CustomTextFieldProps = {} & TextFieldProps;

export const PasswordField: React.FC<CustomTextFieldProps> = ({ ...rest }) => {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={"password"}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <MuiTextField
          label="Password"
          value={value}
          name="password"
          onChange={onChange}
          inputRef={ref}
          {...rest}
          type={showPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
