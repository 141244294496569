import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import PendingPlanterTalliesPerPlanterTable from "../tables/PendingPlanterTalliesPerPlanterTable";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { PageCard } from "./PageCard";
import { CardTitle } from "./CardTitle";

// ALL TEXT ON THE SCREEN (FOR LANGUAGE PURPOSES LATER)
const TXT_TITLE_PENDING_TALLIES = "PLANTER TALLIES PENDING APPROVAL";
const TXT_INFO_NO_PENDING_TALLIES =
  "All of your submitted daily planter tallies have been approved.";

export default function PendingDailyPlanterTalliesPerPlanterCard() {
  // TODO: NOT HARD CODED
  const pendingExists = false;

  return (
    <PageCard>
      <Stack>
        <CardTitle title={TXT_TITLE_PENDING_TALLIES} />
        {pendingExists ? (
          <PendingPlanterTalliesPerPlanterTable />
        ) : (
          <Stack
            direction="row"
            sx={{
              m: 1,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <DoneOutlineIcon
                sx={{
                  height: "100%",
                  color: "white",
                }}
              />
            </Box>
            <Box
              sx={{
                m: 1,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                color: "white",
              }}
            >
              <Typography variant="body1">
                {TXT_INFO_NO_PENDING_TALLIES}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </PageCard>
  );
}
