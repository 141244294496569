import React, { useEffect, useState } from "react";
import { TabPanel } from "../TabPanel";
import SubmitDailyPlanterTallyCard from "../../cards/SubmitDailyPlanterTallyCard";
import { Box, Stack } from "@mui/material";
import PendingDailyPlanterTalliesPerPlanterCard from "../../cards/PendingDailyPlanterTalliesPerPlanterCard";

// TAB PROPERTIES
interface Props {
  value: any;
  theme: any;
  index: number;
}

export const SubmitPlanterTallyTab: React.FC<Props> = ({
  value,
  theme,
  index,
}) => {
  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Stack
        spacing={3}
        sx={{
          width: "100%",
        }}
      >
        <SubmitDailyPlanterTallyCard />
        <PendingDailyPlanterTalliesPerPlanterCard />
      </Stack>
    </TabPanel>
  );
};
