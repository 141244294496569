import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { TextField } from "./TextField";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "../../../App";

interface Props {
  name: string;
  label: string;
  blockIndex: number;
}

export const NestedFieldArray: React.FC<Props> = ({
  name,
  label,
  blockIndex,
  ...rest
}) => {
  const { control, watch } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `blocks.${blockIndex}.seedlots`,
  });
  const seedlotsWatch = watch(`blocks.${blockIndex}.seedlots`);

  return (
    <Box>
      <Stack
        direction="column"
        sx={{
          // m: 1,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ marginBottom: 2 }}>
          {fields.map((seedlot, seedlotIndex) => (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                name={`blocks.${blockIndex}.seedlots.${seedlotIndex}.name`}
                label="Seedlot"
                sx={{
                  // m: 1,
                  width: "100%",
                }}
              />
              <TextField
                name={`blocks.${blockIndex}.seedlots.${seedlotIndex}.bundleSize`}
                label="Size"
                sx={{
                  // m: 1,
                  width: 150,
                }}
              ></TextField>
              <TextField
                name={`blocks.${blockIndex}.seedlots.${seedlotIndex}.bundleCount`}
                label="Count"
                sx={{
                  // m: 1,
                  width: 150,
                }}
              ></TextField>
              <TextField
                name={`blocks.${blockIndex}.seedlots.${seedlotIndex}.seedlot_total`}
                label="Total"
                sx={{
                  //  m: 1,
                  width: 175,
                }}
                value={
                  seedlotsWatch[seedlotIndex].bundleSize *
                  seedlotsWatch[seedlotIndex].bundleCount
                }
              ></TextField>
              <Button
                sx={{
                  padding: 0,
                  // m: 1,
                }}
                // variant="outlined"
                onClick={() => remove(seedlotIndex)}
              >
                <ClearIcon />
              </Button>
            </Stack>
          ))}
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button
            sx={{
              color: "white",
              background: theme.palette.secondary.main,
              border: "solid",
              borderColor: "white",
              width: "50%",
            }}
            variant="contained"
            onClick={() => {
              append({ name: "", bundleSize: 15, bundleCount: 0 });
            }}
          >
            Add A Seedlot
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default NestedFieldArray;
